<template>
  <span class="verify-certificate-button" v-if="hasPerm('certificates.change_certificate')">
    <a href @click.prevent="showModal" class="btn btn-sm btn-primary" :class="{ disabled: isDisabled(), }">
      <i class="fa fa-certificate"></i>
      Vérifier
    </a>
    <b-modal dialog-class="modal-xl"
      :id="getId()"
      cancel-title="Refuser"
      :ok-disabled="isDisabled()"
      @ok.prevent="markAsVerified(true)"
      ok-variant="primary"
      ok-title="Accepter"
      @cancel.prevent="markAsVerified(false)"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-certificate"></i> vérifier un justificatif</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col cols="6">
          <b-row class="">
            <b-col>
              {{ certificateType.name }}
            </b-col>
          </b-row>
          <b-row class="">
            <b-col>
              <div v-if="individual.id">{{ individual.firstAndLastName() }}</div>
              <div v-else>{{ entity.name }}</div>
            </b-col>
          </b-row>
          <b-row v-if="certificate.comments" class="comments">
            <b-col>
              <div>
                <i class="fa fa-comment"></i> Message de la famille
              </div>
              {{ certificate.comments }}
            </b-col>
          </b-row>
          <b-row v-if="certificate.adminComments" class="comments">
            <b-col>
              <div>
                <i class="fa fa-comment-alt"></i> commentaire de {{ certificate.verifiedBy }}
              </div>
              {{ certificate.adminComments }}
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6">
          <div v-for="doc of certificate.documents" :key="doc.id" class="field-line">
            <a @click.prevent="onViewDocument(doc)" href target="_blank">
              {{ doc.getFileName() }}
              <i class="fa fa-expand-arrows-alt"></i>
            </a>
          </div>
        </b-col>
      </b-row>
      <b-row class="box-area">
        <b-col>
          <b-form-group id="comments-group"
            label="Message pour la famille"
            label-for="comments"
            description="Ce commentaire apparaîtra sur le portail Famille"
          >
            <b-textarea id="comments" v-model="comments"></b-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { getRandomId } from '@/utils/random'
import { BackendApi, openDocument } from '@/utils/http'
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import { makeCertificate } from '@/types/certificates'

export default {
  name: 'verify-certificate-button',
  mixins: [BackendMixin],
  props: {
    individual: Object,
    entity: Object,
    certificateType: Object,
    certificate: Object,
  },
  data() {
    return {
      randomId: getRandomId(),
      selectedDoc: null,
      comments: '',
      errorText: '',
    }
  },
  computed: {
  },
  watch: {
    individual: function() { this.init() },
    entity: function() { this.init() },
    certificateType: function() { this.init() },
    certificate: function(newValue, oldValue) {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addWarning', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
      this.init()
    },
    noop() {
    },
    init() {
      this.comments = ''
      if (this.certificate.documents.length) {
        this.selectedDoc = this.certificate.documents[0]
      } else {
        this.selectedDoc = null
      }
    },
    getId() {
      return 'bv-modal-verify-certificate' + this.randomId
    },
    isDisabled() {
      return false
    },
    async markAsVerified(verified) {
      this.errorText = ''
      try {
        const url = '/certificates/api/verify-certificate/' + this.certificate.id + '/'
        const backendApi = new BackendApi('post', url)
        let data = {
          verified: verified,
          admin_comments: this.comments,
          notify: true,
        }
        const resp = await backendApi.callApi(data)
        const updatedCertificate = makeCertificate(resp.data.certificate)
        const notified = !!resp.data.notified
        if (verified) {
          await this.addSuccess('Le document a été accepté')
        } else {
          await this.addWarning('Le document a été refusé')
        }
        if (notified) {
          await this.addSuccess('Un email a été envoyé à la personne qui avait téléchargé le document.')
        }
        this.comments = ''
        this.$bvModal.hide(this.getId())
        this.$emit('verified', { certificate: updatedCertificate, verified: verified, })
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async onViewDocument(document) {
      const docUrl = '/certificates/download-certificate-document/<key>/'
      const docSlug = '' + document.id
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
.comments {
  margin: 5px 0;
  padding: 5px;
  background: #f0f0f0;
}
.box-area {
  margin-top: 20px;
  padding: 10px;
  border-top: solid 1px #eee;
}
</style>
