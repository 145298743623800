<template>
  <div class="certificates" v-if="hasPerm('certificates.view_certificate')">
    <page-header title="Justificatifs" icon="fa fa-certificate" :links="getLinks()"></page-header>
    <div class="sub-header" v-show="initialLoadingDone">
      <b-row>
        <b-col cols="8">
          <h2>
            <counter-label :counter="certificatesCount" label="document"></counter-label>
          </h2>
        </b-col>
        <b-col cols="4" class="text-right">
          <pagination
            :pages-count="pagesCount"
            :has-next="hasNext"
            :has-previous="hasPrevious"
            :selector="paginationSelector"
            @change="onPageChanged($event)"
            css-style="justify-content: flex-end;"
          >
          </pagination>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-select
            id="filter-type"
            v-model="selectedFilter"
            @change="onFilterSelected"
          >
            <b-form-select-option
              :value="item"
              v-for="item in filterChoices"
              :key="item.id"
            >
              {{ item.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="3">
          <b-form-select
            id="certificate-types"
            v-model="selectedCertificateType"
            @change="onCertificateTypeSelected"
            >
            <b-form-select-option
              :value="item"
              v-for="item in certificateTypes"
              :key="item.id"
            >
              {{ item.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="3">
          <span v-if="selectedCertificateType && selectedCertificateType.forField">
            <a href @click.prevent="viewByFields()">
              Voir les justificatifs des personnes concernées
            </a>
          </span>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div class="empty-text"  v-if="!isLoading(loadingName) && certificates.length === 0">Aucun document</div>
    <table class="table table-striped" v-if="!isLoading(loadingName) && certificates.length">
      <thead>
        <tr>
          <td>
            <b>Date</b>
          </td>
          <td>
            <b>Statut</b>
          </td>
          <td>
            <b>Document</b>
          </td>
          <td>
            <b></b>
          </td>
          <td>
            <b>Fichiers</b>
          </td>
        </tr>
      </thead>
      <tbody>
      <tr v-for="certificate in certificates" :key="certificate.id">
        <td>
          {{ certificate.createdOn | dateToString }}<br />
        </td>
        <td>
          <span v-if="certificate.verifiedOn">
            <span v-if="certificate.refused" class="badge badge-danger">
              Refusé le {{ certificate.createdOn | dateToString }}
            </span>
            <span v-else class="badge badge-success">
              Vérifié le {{ certificate.createdOn | dateToString }}
            </span>
            <br />
          </span>
          <span v-else class="badge badge-primary">
            A Vérifier
          </span>
          <div v-if="certificate.verifiedBy" class="small2">
            {{ certificate.verifiedBy }}
          </div>
        </td>
        <td>
          {{ certificate.certificateType.name }}
          <span class="badge badge-dark" v-if="certificate.certificateType.isRequired">Obligatoire</span>
          <span class="badge badge-light" v-else>Facultatif</span>
        </td>
        <td>
          <router-link :to="getLinkToEntity(certificate)">
            {{ certificate.applyTo() }}
          </router-link>
        </td>
        <td>
          <verify-certificate-button
            :certificate="certificate"
            :entity="certificate.entity"
            :individual="certificate.individual"
            :certificate-type="certificate.certificateType"
            @verified=refreshOne($event)
          ></verify-certificate-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import Pagination from '@/components/Controls/Pagination'
import PageHeader from '@/components/Layout/PageHeader'
import VerifyCertificateButton from '@/components/Certificates/VerifiyCertificateButton'
import { BackendApi } from '@/utils/http'
import { makeChoice } from '@/types/base'
import { CertificateStatus, makeCertificate, makeCertificateType } from '@/types/certificates'

export default {
  name: 'Certificates',
  mixins: [BackendMixin],
  components: {
    VerifyCertificateButton,
    Pagination,
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'certificates',
      certificates: [],
      certificatesCount: 0,
      page: 0,
      hasNext: false,
      hasPrevious: false,
      initialLoadingDone: false,
      selectedCertificateType: makeCertificateType(),
      certificateTypes: [],
      selectedFilter: null,
      filterChoices: [
        makeChoice({ id: CertificateStatus.Waiting, name: 'À vérifier', }),
        makeChoice({ id: CertificateStatus.Verified, name: 'Vérifiés', }),
        makeChoice({ id: CertificateStatus.Accepted, name: 'Acceptés', }),
        makeChoice({ id: CertificateStatus.Refused, name: 'Refusés', }),
        makeChoice({ id: CertificateStatus.All, name: 'Tous', })
      ],
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.certificatesCount / 100)
    },
    paginationSelector() {
      return ''
    },
  },
  mounted() {
    const certificateType = +(this.$route.query ? this.$route.query.type : 0)
    const filtering = +(this.$route.query ? this.$route.query.filter : 2)
    this.onLoaded(certificateType, filtering)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded(certificateType, filtering) {
      this.certificateTypes = [makeCertificateType({ name: 'Tous les types de documents', })]
      await this.loadCertificateTypes()
      let theIndex = 0
      if (certificateType) {
        theIndex = this.certificateTypes.map(elt => elt.id).indexOf(certificateType)
        theIndex = (theIndex < 0) ? 0 : theIndex
      }
      this.selectedCertificateType = this.certificateTypes[theIndex]
      theIndex = 0
      if (filtering) {
        theIndex = this.certificateTypes.map(elt => elt.id).indexOf(filtering)
        theIndex = this.certificateTypes.map(elt => elt.id).indexOf(filtering)
        theIndex = (theIndex < 0) ? 0 : theIndex
      }
      this.selectedFilter = this.filterChoices[theIndex]
      await this.loadElements(1)
    },
    onPageChanged(event) {
      this.page = event.page
      if (this.initialLoadingDone) {
        this.loadElements()
      }
    },
    onCertificateTypeSelected() {
      if (this.initialLoadingDone) {
        this.loadElements()
      }
    },
    onFilterSelected() {
      if (this.initialLoadingDone) {
        this.loadElements()
      }
    },
    async loadElements() {
      this.startLoading(this.loadingName)
      let url = '/certificates/api/certificates/?page=' + (this.page || 1)
      if (this.selectedFilter) {
        url += '&filter=' + this.selectedFilter.id
      }
      if (this.selectedCertificateType.id) {
        url += '&certificate_type=' + this.selectedCertificateType.id
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.certificatesCount = resp.data.count
        this.hasNext = !!resp.data.next
        this.hasPrevious = !!resp.data.previous
        this.certificates = resp.data.results.map(elt => makeCertificate(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.initialLoadingDone = true
      this.endLoading(this.loadingName)
    },
    async loadCertificateTypes() {
      try {
        let url = '/certificates/api/certificate-types/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.certificateTypes = this.certificateTypes.concat(resp.data.map(elt => makeCertificateType(elt)))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinkToEntity(elt) {
      return {
        name: 'families-detail',
        params: {
          entityId: '' + elt.entity.id,
        },
      }
    },
    refreshOne(event) {
      for (let index = 0; index < this.certificates.length; index++) {
        if (event.certificate.id === this.certificates[index].id) {
          this.certificates[index] = event.certificate
          this.certificates = this.certificates.concat([])
          break
        }
      }
    },
    viewByFamilies() {
      this.$router.push({ name: 'certificates-by-entities', })
    },
    viewByFields() {
      if (this.selectedCertificateType && this.selectedCertificateType.forField) {
        this.$router.push(
          {
            name: 'certificates-by-fields',
            params: { certificateType: this.selectedCertificateType.id, },
          }
        )
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'État par famille',
          callback: this.viewByFamilies,
          icon: 'fa fa-table',
          cssClass: 'btn-secondary',
        }
      ]
    },
  },
}
</script>

<style scoped lang="less">
</style>
