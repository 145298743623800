import { makeSchoolYear, SchoolYear } from '@/types/schools'
import { Entity, Individual, makeEntity, makeIndividual } from '@/types/people'
import { getFileNameFromUrl, getShortenFileNameFromUrl } from '@/utils/files'

export enum CertificateStatus {
  All= 0,
  Verified = 1,
  Waiting = 2,
  Accepted = 3,
  Refused = 4
}

export class CertificateType {
  constructor(
    public id: number,
    public name: string,
    public isRequired: boolean,
    public description: string,
    public canFamilyAccess: boolean,
    public isPerpetual: boolean,
    public isIndividualLink: boolean,
    public forField: number
  ) {
  }
}

export function makeCertificateType(jsonData: any = null): CertificateType {
  if (jsonData === null) {
    jsonData = {}
  }
  return new CertificateType(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.is_required,
    jsonData.description || '',
    !!jsonData.can_family_access,
    !!jsonData.is_perpetual,
    !!jsonData.is_individual_link,
    jsonData.for_field || 0
  )
}

export class CertificateDocument {
  constructor(
    public id: number,
    public fileName: string
  ) {
  }

  public getFileName(): string {
    return getFileNameFromUrl(this.fileName)
  }

  public getShortenFileName(): string {
    return getShortenFileNameFromUrl(this.fileName)
  }
}

export class Certificate {
  constructor(
    public id: number,
    public certificateType: CertificateType,
    public schoolYear: SchoolYear,
    public entity: Entity,
    public individual: Individual,
    public createdBy: string,
    public createdOn: Date,
    public verifiedBy: string,
    public verifiedOn: Date|null,
    public refused: boolean,
    public deprecated: boolean,
    public comments: string,
    public adminComments: string,
    public documents: CertificateDocument[]
  ) {
  }

  public applyTo(): string {
    if (this.individual.id) {
      return this.individual.firstAndLastName()
    } else {
      return 'Famille ' + this.entity.name
    }
  }
}

export function makeCertificateDocument(jsonData: any = null): CertificateDocument {
  if (jsonData === null) {
    jsonData = {}
  }
  return new CertificateDocument(
    jsonData.id || 0,
    jsonData.file_name || ''
  )
}

export function makeCertificate(jsonData: any = null): Certificate {
  if (jsonData === null) {
    jsonData = {}
  }
  const documents = jsonData.documents || []
  return new Certificate(
    jsonData.id,
    makeCertificateType(jsonData.certificate_type),
    makeSchoolYear(jsonData.school_year),
    makeEntity(jsonData.entity),
    makeIndividual(jsonData.individual),
    jsonData.created_by || '',
    jsonData.created_on,
    jsonData.verified_by || '',
    jsonData.verified_on,
    !!jsonData.refused,
    !!jsonData.deprecated,
    jsonData.comments,
    jsonData.admin_comments,
    documents.map((elt: any) => makeCertificateDocument(elt))
  )
}

export class CertificateRequest {
  constructor(
    public certificateType: CertificateType,
    public schoolYear: SchoolYear,
    public individual: Individual,
    public certificate: Certificate | null
  ) {
  }

  public getKey(): string {
    return [this.certificateType.id, this.schoolYear.id, this.individual.id].map(
      (elt: number) => ('' + elt)
    ).join(':')
  }

  public showUploader(): boolean {
    return ((this.certificate === null) || (this.certificate.refused) || (this.certificate.deprecated))
  }

  public getValidationYear(): string {
    if (
      (this.certificate) &&
      (this.certificateType.isPerpetual) &&
      (this.schoolYear.id !== this.certificate.schoolYear.id)
    ) {
      return 'en ' + this.certificate.schoolYear.name
    }
    return ''
  }
}

export function makeCertificateRequest(jsonData: any = null): CertificateRequest {
  if (jsonData === null) {
    jsonData = {}
  }
  return new CertificateRequest(
    makeCertificateType(jsonData.certificate_type),
    makeSchoolYear(jsonData.school_year),
    makeIndividual(jsonData.individual),
    jsonData.certificate ? makeCertificate(jsonData.certificate) : null
  )
}
