
export function getFileNameFromUrl(url: string): string {
  const elements = url.split('/')
  if (elements.length) {
    return elements[elements.length - 1]
  }
  return url
}

export function getShortenFileNameFromUrl(url: string): string {
  const value: string = getFileNameFromUrl(url)
  if (value.length > 12) {
    const dotPos = value.lastIndexOf('.')
    if (dotPos > 0) {
      return value.substr(0, 9) + '..' + value.substr(dotPos)
    } else {
      return value.substr(0, 12)
    }
  }
  return value
}
